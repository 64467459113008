* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --primaryColor: #a15804e5;
  --mainWhite: #fff;
  --offWhite: #f7f7f7;
  --mainBlack: #222;
  --mainGrey: #ececec;
  --darkGrey: #cfcfcf;
  --mainTransition: all 0.3s linear;
  --mainSpacing: 3px;
  --lightShadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
  --darkShadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
}
/* globals */
body {
  padding-top: 66px;
  color: var(--mainBlack);
  background: var(--mainWhite);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  line-height: 1.4;
}
h1 {
  font-size: 3em;
  line-height: 1;
  margin-bottom: 0.5em;
}
h2 {
  font-size: 2em;
  margin-bottom: 0.75em;
}
h3 {
  font-size: 1.5em;
  line-height: 1;
  margin-bottom: 1em;
}
h4 {
  font-size: 1.2em;
  line-height: 1.25;
  margin-bottom: 1.25em;
}
h5 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.5em;
}
h6 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.5em;
}

.btn-primary {
  display: inline-block;
  text-decoration: none;
  letter-spacing: var(--mainSpacing);
  color: var(--mainBlack);
  background: var(--primaryColor);
  padding: 0.4rem 0.9rem;
  border: 3px solid var(--primaryColor);
  transition: var(--mainTransition);
  text-transform: uppercase;
  cursor: pointer;
}
.btn-primary:hover {
  background: transparent;
  color: var(--primaryColor);
}
.loading {
  text-transform: capitalize;
  text-align: center;
  margin: 2rem auto;

}
.loading img {
  height: 2rem;
  width: 2rem;
}
.error {
  text-align: center;
  text-transform: uppercase;
  margin: 5rem 0;
}
.empty-search {
  text-align: center;
  text-transform: capitalize;
  margin: 2rem 0;
  padding: 1rem;
  letter-spacing: var(--mainSpacing);
}
/* end of globals */
/* Navbar */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.5rem 2rem;
  background: black;
  z-index: 2;
  transition: var(--mainTransition);

}
.toggleOff {
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
  position: fixed;
  top: 10vh;
  left: 0;
  transition: var(--mainTransition);
}
.nav-bar-curve {
  border-bottom-left-radius: 3rem;
  border-bottom-right-radius: 3rem;
  transition: var(--mainTransition);
}
.nav-header {
  display: flex;
  justify-content: space-between;
}

.nav-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}
.nav-icon {
  font-size: 1.5rem;
  color: var(--primaryColor);
  transition: var(--mainTransition);
}

.side-icon {
  font-size: 1.5rem;
  color: var(--primaryColor);
  transition: var(--mainTransition);
  margin: -0.5rem 1.5rem;
}

.social-icon {
  font-size: 2rem;
  transition: var(--mainTransition);
  margin: -0.5rem 1.5rem;
  color: var(--primaryColor);
}
.social-icon:hover {
  color: var(--primaryColor);
}
.youtube {
  color:rgb(252, 11, 11);
}
.facebook {
  color: rgb(92, 92, 245);
}
.instagram {
  color: rgb(224, 28, 159)
}

.nav-links {
  height: 0;
  overflow: hidden;
  transition: var(--mainTransition);
  list-style-type: none;
}
.nav-links a {
  display: block;
  text-decoration: none;
  padding: 1rem 0 0 0;
  /* color: var(--mainBlack); */
  color: white;
  transition: var(--mainTransition);
  text-align: left;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: var(--mainSpacing);
}
.nav-links a:hover {
  color: var(--primaryColor);
  font-size: larger;
}

.show-nav {
  height: 8rem;
  
}

.dest-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
@media screen and (min-width: 768px) {
  .nav-btn {
    display: none;
  }
  .side-icon {
    display: none;
  }
  .nav-center {
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
  }
  .nav-links {
    height: auto;
    display: flex;
    margin-left: 4rem;
    
  }
  .nav-links a {
    margin: 0 1rem;
    padding: 0.5rem 0;
    
  }
  .push {
    margin-left: 50px;
  }
  .nav-links a {
    font-size: larger;
  }
  .nav-links a:hover {
    font-size: x-large;
  }
}
/* end of navbar */


/* Hero */
.roomsHero {
  min-height: 60vh;
  background: url("./images/cover_photo.svg") center/contain no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

/* End of Hero */
/* Banner */
.banner {
  display: inline-block;
  background: rgba(0, 0, 0, 0.5);
  color: var(--mainWhite);
  padding: 2rem 1rem;
  text-align: center;
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
}
.banner h1 {
  font-size: 2rem;
}
.banner div {
  width: 10rem;
  height: 5px;
  background: var(--primaryColor);
  margin: 1.7rem auto;
}
.banner p {
  font-size: 0.8rem;
  margin-bottom: 2rem;
}
@media screen and (min-width: 576px) {
  .banner {
    padding: 2rem 3rem;
  }
  .banner h1 {
    font-size: 3rem;
  }
}
@media screen and (min-width: 992px) {
  .banner {
    padding: 2rem 6rem;
  }
  .banner h1 {
    font-size: 3rem;
  }
}
/* End of Banner */
/* Title */
.section-title {
  text-align: center;
  margin-bottom: 2rem;
}
.section-title h4 {
  font-size: 1.5rem;
  letter-spacing: var(--mainSpacing);
  text-transform: capitalize;
  margin-bottom: 1rem;
}
.section-title div {
  width: 5rem;
  height: 5px;
  margin: 0 auto;
  background: var(--primaryColor);
}
/* end of Title */

/* Start of Sort Button */

.sort-container {
  display : flex;
  justify-content: space-around;
  width : 100%;
  background-color: var(--darkGrey);
  padding : 0px;

}

.basic-sort {
  background-color: black;
  padding: 15px;
  margin : 10px;
  margin-bottom: 0px;
  color : white;
  border-radius: 10%;
  transition: var(--mainTransition);
}

.active-sort {
  background-color: var(--primaryColor);
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  transition: var(--mainTransition);
}

.show-sort-icon {
  color : var(--primaryColor);
  font-size: larger;
  text-align: center;
  transition: var(--mainTransition);
}

.hide-sort-icon {
  display: none;
  transition: var(--mainTransition);
}
/* End of Sort Button */

/* services */
.services {
  padding: 5rem 0;
  
}
.services {
  background: var(--darkGrey);
  text-align: center;
  margin: 0 auto;
}
.services-center {
  width: 90vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 50px;
}
.service span {
  display: inline-block;
  color: var(--primaryColor);
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}
.services h6 {
  letter-spacing: var(--mainSpacing);
  text-transform: capitalize;
}
.services p {
  width: 80%;
  margin: 0 auto;
}
@media screen and (min-width: 992px) {
  .services-center {
    width: 95vw;
    max-width: 1170px;
  }
}

@media screen and (min-width: 1200px) {
  .services p {
    width: 100%;
  }
}
/*end of services */
/* featured rooms */

.featured-rooms {
  padding: 5rem 0;
}
.featured-rooms-center {
  width: 80vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 40px;
}
@media screen and (min-width: 776px) {
  .featured-rooms-center {
    width: 90vw;
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  }
}
@media screen and (min-width: 992px) {
  .featured-rooms-center {
    width: 95vw;
    max-width: 1170px;
  }
} */
/* end of featured rooms
/* room */

.room {
  box-shadow: var(--lightShadow);
  transition: var(--mainTransition);
  border-left:  transparent;

}
.room:hover {
  box-shadow: var(--darkShadow);
}

.img-container {
  position: relative;
}
.img-container img {
  width: 100%;
  height: 30vh;
  display: block;
  transition: var(--mainTransition);
}
.btn-room {
  display: inline-block;
  text-decoration: none;
  letter-spacing: var(--mainSpacing);
  color: var(--mainBlack);
  background: transparent;
  padding: 0 0.9rem;
  border: 1px solid transparent;
  transition: var(--mainTransition);
  text-transform: capitalize;
  cursor: pointer;
}
.btn-room:hover {
  background: transparent;
  color: var(--primaryColor);
}
.verified {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  color: rgb(22, 179, 22);
  padding: 0.3rem 0.6rem 0.5rem;
  border-bottom-right-radius: 1rem;
  font-size: 0.7rem;
  text-align: center;
  font-weight: bold;
  transition: var(--mainTransition);
}

.available, .occupied {
  height:0.7rem;
  width: 0.7rem;
  float: right;
  border-radius: 50%;
  margin-right:0.5rem;
}
.available{
  background: rgb(24, 151, 24);
}
.occupied{
  background: var(--darkGrey);;
}
.room-link {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: scale(0);
  transition: all 0.3s linear;
}
.img-container:hover {
  background: rgba(0, 0, 0, 0.8);
}
.img-container:hover img {
  opacity: 0.3;
}
.img-container:hover .verified {
  opacity: 0;
}
.img-container:hover .room-link {
  transform: translate(-50%, -50%) scale(1);
}
.room-info {
  background: var(--darkGrey);
  text-transform: capitalize;
  padding: 0.5rem 0;
  text-align: center;
  font-weight: 700;
  letter-spacing: var(--mainSpacing);
}
/* end of room  */
/* single room*/

.single-room {
  padding: 2rem 0 0 0;
}
.single-room-images {
  width: 80vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 50px;
}
.single-room-images img {
  width: 100%;
  display: block;
}
.single-room-info {
  width: 80vw;
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 auto;
}
.disclaimer {
  width: 80vw;
  margin: 0 auto 3rem auto;
}
.disclaimer h4 {
  color: rgb(218, 13, 13);
  margin: 2rem 0 0.5rem;
}
.disclaimer em {
  color: grey;
  font-size: small;
}
.disclaimer em span {
  font-weight: bold;
  color : black;
  
}
.desc,
.info {
  margin: 1rem 0;
  
}
.info {
  line-height: 0.5;
  padding-left: 0.5rem;
  
}
.info h6 span{
  color:grey;
  
}
.desc h3 {
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
}
.desc p {
  line-height: 1.5;
}
.info h3,
.info h6 {
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
  letter-spacing: 1px;
  

}

.info h6 {
  font-weight: 300;
}
.format-text{
  line-height: 1.5em;
}
.resize {
  font-size: 0.8em;
}
.room-extras {
  width: 80vw;
  margin: 0 auto 3rem auto;
}
.room-extras h6 {
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
}
.extras {
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
}
/* start of swiper image */

.swiper-container {
  width: 293px;
  height: 220px;
  
  
}

.swiper-wrapper{
  padding-inline-start: 0;
}
.swiper-pagination-bullet-active  {
  background-color: var(--primaryColor) !important;
}

/* end of swiper */
@media screen and (min-width: 992px) {
  .single-room-images,
  .single-room-info,
  .room-extras, .disclaimer {
    width: 95vw;
    max-width: 1170px;
  }
  .single-room-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
  }
  
}
/* end of single room*/

/* Start of roomie */
.center-align-roomie {
  display: flex;
  justify-content: center;
}
.avatar {
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  width: 100px;
}

.avatar:not(:first-child) {
  margin-left: -60px;
  -webkit-mask:radial-gradient(circle 55px at 5px 50%,transparent 99%,#fff 100%);
          mask:radial-gradient(circle 55px at 5px 50%,transparent 99%,#fff 100%);
}

.avatar img {
  width: 100%;
  display: block;
}


/* End of roomie  */

/* roomlist */
.roomslist {
  padding: 5rem 0;
  padding-top:0;
}
.roomslist-center {
  width: 80vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 30px;
}

@media screen and (min-width: 776px) {
  .roomslist-center {
    width: 90vw;
  }
  .swiper-container {
    width: 400px;
    height: 300px;
    
  }
  
}
@media screen and (min-width: 992px) {
  .roomslist-center {
    width: 95vw;
    max-width: 1170px;
  }
  .swiper-container {
    width: 570px;
    height: 412px;
    
  }
  .swiper-container .swiper-zoom-container>img {
    width: 100% !important;
    height: auto !important;
    object-fit: cover !important;
    object-position: center !important;
}

}
/* end of roomlist */
/* Override range styling */
@media screen and (-webkit-min-device-pixel-ratio:0) {
  input[type='range'] {
    overflow: hidden;
    width: 100%;
    -webkit-appearance: none;
    background-color: var(--darkGrey);
  }
  
  input[type='range']::-webkit-slider-runnable-track {
    height: 10px;
    -webkit-appearance: none;
    color: var(--mainBlack);
    margin-top: -1px;
  }
  
  input[type='range']::-webkit-slider-thumb {
    width: 10px;
    -webkit-appearance: none;
    height: 10px;
    cursor: ew-resize;
    background: var(--darkGrey);
    box-shadow: -80px 0 0 80px var(--primaryColor);
  }

}
/** FF*/
input[type="range"]::-moz-range-progress {
background-color: var(--primaryColor); 
}
input[type="range"]::-moz-range-track {  
background-color: var(--darkGrey);
}
/* IE*/
input[type="range"]::-ms-fill-lower {
background-color: var(--primaryColor); 
}
input[type="range"]::-ms-fill-upper {  
background-color: var(--darkGrey);
}


/* end of range styling */

/*  rooms fitler*/
.filter-container {
  padding: 1.5rem 0;
  padding-bottom: 2rem;
}
.filter-form {
  width: 60vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 10px;
  
}

.form-group {
  text-transform: capitalize;
}
.format-sch {
  text-transform: uppercase;
}
.format-value {
  text-transform: capitalize;
}
.form-group label {
  display: block;
  letter-spacing: var(--mainSpacing);
  margin-bottom: 0.5rem;
}
.form-control {
  width: 100%;
  background: transparent;
  font-size: 1rem;
}
.size-inputs {
  display: flex;
}
.size-input {
  width: 40%;
  padding: 0.2rem;
  border: 1px solid var(--mainBlack);
  border-radius: 0.3rem;
  margin-right: 0.3rem;
}
.single-extra label {
  display: inline-block;
  font-size: 0.8rem;
  margin-left: 0.5rem;
}
@media screen and (min-width: 776px) {
  .filter-form {
    width: 70vw;
  }
}
@media screen and (min-width: 992px) {
  .filter-form {
    width: 95vw;
    max-width: 1170px;
  }
}
/* end of rooms fitler*/
/* footer */
.footer {
  
  width: 100%;
  padding: 2rem 2rem;
  background: black;
  text-align: center;
  color: white;

}

.footer span, .footer h6 > p {
  color: grey;
  text-decoration: none;
}
.footer p {
  color : grey;
  font-size: 0.8rem;
  text-decoration: underline;
}
.footer a {
  color: white;
}
/* end of footer */
